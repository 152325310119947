<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t("friend_suggestion_bonus.head") }} </h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("friend_suggestion_bonus.head_list") }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input ref="startDate" :date="dateFrom" :label="$t('friend_suggestion_bonus.date_from')"
                @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input ref="endDate" :date="dateTo" :label="$t('friend_suggestion_bonus.date_to')"
                @setValue="dateTo = $event" />
            </span>

            <v-btn color="primary" @click="searchBonus" class=""> {{ $t('search') }} </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-title>
          {{ $t('friend_suggestion_bonus.summary_bonus') }} : &nbsp;<span style="color: #28c76f !important">{{ total |
            currency
          }}</span></v-card-title>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <!-- <span>สรุปยอดโบนัสแนะนำเพื่อน</span> -->
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :page.sync="pagination.currentPage" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="members">
            <template v-slot:[`item.amount`]="{ item }">
              {{ item.amount | currency }}
            </template>
            <template v-slot:[`item.tureover`]="{ item }">
              {{ item.tureover | currency }}
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      headers: [
        {
          text: this.$t('friend_suggestion_bonus.tel'),
          value: 'member',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('friend_suggestion_bonus.play'),
          value: 'tureover',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('friend_suggestion_bonus.receiver'),
          value: 'refMember',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('friend_suggestion_bonus.type'),
          value: 'type',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('friend_suggestion_bonus.play_percent'),
          value: 'percent',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('friend_suggestion_bonus.amount'),
          value: 'amount',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('friend_suggestion_bonus.date'),
          value: 'date',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      members: [],
      loading: false,
      dummy: true,
      pagination: {
        currentPage: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      ketType: {
        turnover: this.$t('friend_suggestion_bonus.turnover'),
        turnwinloss: this.$t('friend_suggestion_bonus.turnwinlose'),
      },
      total: 0,
    }
  },
  async created() {
    this.addLogPage()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getAffiliate(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && !this.dummy) await this.getAffiliate(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && this.dummy) await this.getAffiliate(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'โบนัสแนะนำเพื่อน',
            url: window.location.href,
            detail: 'รายการโบนัสแนะนำเพื่อน',
            admin: userSystem.name ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async searchBonus() {
      this.dummy = false
      await this.getAffiliate(
        1,
        this.pagination.rowsPerPage,
        this.$refs.startDate.value,
        this.$refs.endDate.value,
        this.searchData
      )
      this.pagination.currentPage = 1
    },
    async getAffiliate(page, row, time_from, time_to, search) {
      this.loading = true
      let total = await this.$store.dispatch('getAffiliateTotal', {
        time_from: this.formatDateYYYY(this.$refs.startDate.value),
        time_to: this.formatDateYYYY(this.$refs.endDate.value)
      })
      this.total = Number(total.sum_total).toFixed(2)
      let rs = await this.$store.dispatch('getAffiliate', {
        page: page,
        rows: row,
        ...(!search ? { page: page } : {}),
        ...(!search ? { rows: row } : {}),
        ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
        ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
        ...(search ? { search: search } : {}),
      })
      this.pagination.totalItems = rs.count
      this.members = []
      rs.data.forEach(element => {
        this.members.push({
          id: element.id,
          member: element.member?.phone,
          tureover: element.tureover ? element.tureover : 0,
          refMember: element.ref_member?.phone,
          type: `${element?.type_bonus ? this.ketType[element.type_bonus] : ''}(${element.type_game})`,
          percent: element.percent,
          amount: element.amount,
          date: element.created_at ? moment(element.created_at).format('YY-MM-DD HH:mm') : '',
        })
      })
      this.loading = false
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      await this.getAffiliate(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.currentPage = 1
      // test
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
